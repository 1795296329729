import { combineReducers } from 'redux';
import { createHashHistory } from 'history';
import authReducer from './auth/reducer';
import generalReducer from './general/reducer';
import freshServiceReducer from './freshService/reducer';
import { configureStore } from '@reduxjs/toolkit';

export const hashedHistory = createHashHistory();

export const createRootReducer = combineReducers({
    auth: authReducer,
    general: generalReducer,
    freshService: freshServiceReducer
});

export const store = configureStore({
    reducer: createRootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
