import { RightGrantsEnum } from './@types/enum/rightGrantsEnum';

export const IMPORTED_TYPE_INPUT_FIELD = 'Cannot change this input field if this entry imported.';

export const QUERY_DATA_STALE_TIME = 30 * 60 * 1000;

export const SNACKBAR_WIDTH = 400;

export enum RIGHT_GRANTS {
    View = 0,
    Add = 1,
    Edit = 2,
    Delete = 3,
    Admin = 20,
}

export const RIGHT_LEVELS : Record<string, number> = {
    'View': RIGHT_GRANTS.View,
    'Add': RIGHT_GRANTS.Add,
    'Edit': RIGHT_GRANTS.Edit,
    'Delete': RIGHT_GRANTS.Delete,
    'Admin': RIGHT_GRANTS.Admin,
};

export const RIGHT_GRANT_LEVELS : Record<string, number> = {
    'View': RightGrantsEnum.View,
    'Add': RightGrantsEnum.Add,
    'Edit': RightGrantsEnum.Edit,
    'Delete': RightGrantsEnum.Delete,
    'Admin': RightGrantsEnum.Admin,
};