/*================================================================================================================
*                                                  Rights
* ==============================================================================================================*/

// users
export const GET_USER_KEY = 'user';
export const GET_USERS_KEY = 'users';
export const UPSERT_USERS_KEY = 'upsertUser';
export const LINK_USER_GOOGLE_ACCOUNT = 'linkUserGoogleAccount';
export const UNLINK_USER_GOOGLE_ACCOUNT = 'unlinkUserGoogleAccount';
export const UPDATE_EMPLOYEE_CREDENTIALS = 'updateEmployeeCredentials';
export const RESET_EMPLOYEE_CREDENTIALS = 'resetEmployeeCredentials';
export const LOGOUT_USERS_KEY = 'logoutUsers';
export const LOGOUT_ALL_USERS_KEY = 'logoutAllUsers';
export const DELETE_USER_KEY = 'deleteUser';

// rights
export const GET_RIGHTS_KEY = 'rights';
export const UPSERT_RIGHT_KEY = 'upsertRight';
export const DELETE_RIGHT_KEY = 'deleteRight';

// roles
export const GET_ROLES_KEY = 'roles';
export const UPSERT_ROLE_KEY = 'upsertRole';
export const DELETE_ROLE_KEY = 'deleteRole';

/*================================================================================================================
*                                                    Master Data
* ==============================================================================================================*/

// Account
export const GET_ACCOUNTS_KEY = 'accounts';
export const UPSERT_ACCOUNT_KEY = 'upsertAccount';
export const DELETE_ACCOUNT_KEY = 'deleteAccount';

// BarcodeRange
export const GET_BARCODE_RANGES_KEY = 'barcodeRanges';
export const UPSERT_BARCODE_RANGE_KEY = 'upsertBarcodeRange';
export const DELETE_BARCODE_RANGE_KEY = 'deleteBarcodeRange';

// ContactDetail
export const GET_CONTACT_DETAILS_KEY = 'contactDetails';
export const UPSERT_CONTACT_DETAIL_KEY = 'upsertContactDetail';
export const DELETE_CONTACT_DETAIL_KEY = 'deleteContactDetail';

// Country
export const GET_COUNTRIES_KEY = 'countries';
export const UPSERT_COUNTRY_KEY = 'upsertCountry';
export const DELETE_COUNTRY_KEY = 'deleteCountry';

// Employee
export const GET_EMPLOYEES_KEY = 'employees';
export const UPSERT_EMPLOYEE_KEY = 'upsertEmployee';
export const DELETE_EMPLOYEE_KEY = 'deleteEmployee';

// EmployeeCard
export const GET_EMPLOYEE_CARDS_KEY = 'employeeCards';
export const UPSERT_EMPLOYEE_CARD_KEY = 'upsertEmployeeCard';
export const DELETE_EMPLOYEE_CARD_KEY = 'deleteEmployeeCard';

// Location
export const GET_LOCATIONS_KEY = 'locations';
export const UPSERT_LOCATION_KEY = 'upsertLocation';
export const DELETE_LOCATION_KEY = 'deleteLocation';

// LocationLine
export const GET_LOCATION_LINES_KEY = 'locationLines';
export const UPSERT_LOCATION_LINE_KEY = 'upsertLocationLine';
export const DELETE_LOCATION_LINE_KEY = 'deleteLocationLine';

// LocationLineType
export const GET_LOCATION_LINE_TYPES_KEY = 'locationLineTypes';
export const UPSERT_LOCATION_LINE_TYPE_KEY = 'upsertLocationLineType';
export const DELETE_LOCATION_LINE_TYPE_KEY = 'deleteLocationLineType';

// Method
export const GET_METHODS_KEY = 'methods';
export const UPSERT_METHOD_KEY = 'upsertMethod';
export const DELETE_METHOD_KEY = 'deleteMethod';

// Nursery
export const GET_NURSERIES_KEY = 'nurseries';
export const UPSERT_NURSERY_KEY = 'upsertNursery';
export const DELETE_NURSERY_KEY = 'deleteNursery';

// Process
export const GET_PROCESSES_KEY = 'processes';
export const UPSERT_PROCESS_KEY = 'upsertProcess';
export const DELETE_PROCESS_KEY = 'deleteProcess';

// Product
export const GET_PRODUCTS_KEY = 'products';
export const UPSERT_PRODUCT_KEY = 'upsertProduct';
export const DELETE_PRODUCT_KEY = 'deleteProduct';

// ProductType
export const GET_PRODUCT_TYPES_KEY = 'productTypes';
export const UPSERT_PRODUCT_TYPE_KEY = 'upsertProductType';
export const DELETE_PRODUCT_TYPE_KEY = 'deleteProductType';

// Profile
export const GET_PROFILES_KEY = 'profiles';
export const UPSERT_PROFILE_KEY = 'upsertProfile';
export const DELETE_PROFILE_KEY = 'deleteProfile';

// Profile
export const GET_REASONS_KEY = 'reasons';
export const UPSERT_REASON_KEY = 'upsertReason';
export const DELETE_REASON_KEY = 'deleteReason';

// Supplier
export const GET_SUPPLIERS_KEY = 'suppliers';
export const UPSERT_SUPPLIER_KEY = 'upsertSupplier';
export const DELETE_SUPPLIER_KEY = 'deleteSupplier';

// TransferType
export const GET_TRANSFER_TYPES_KEY = 'transferTypes';
export const UPSERT_TRANSFER_TYPE_KEY = 'upsertTransferType';
export const DELETE_TRANSFER_TYPE_KEY = 'deleteTransferType';

// UnitOfMeasure
export const GET_UNITS_OF_MEASURE_KEY = 'unitOfMeasures';
export const UPSERT_UNIT_OF_MEASURE_KEY = 'upsertUnitOfMeasure';
export const DELETE_UNIT_OF_MEASURE_KEY = 'deleteUnitOfMeasure';

// UnitOfMeasureType
export const GET_UNIT_OF_MEASURE_TYPES_KEY = 'unitOfMeasureTypes';
export const UPSERT_UNIT_OF_MEASURE_TYPE_KEY = 'upsertUnitOfMeasureType';
export const DELETE_UNIT_OF_MEASURE_TYPE_KEY = 'deleteUnitOfMeasureType';

/*================================================================================================================
*                                                  FARM Master Data
* ==============================================================================================================*/

// Division
export const GET_DIVISIONS_KEY = 'divisions';
export const UPSERT_DIVISION_KEY = 'upsertDivision';
export const DELETE_DIVISION_KEY = 'deleteDivision';

// Subdivision
export const GET_SUBDIVISIONS_KEY = 'subdivisions';
export const UPSERT_SUBDIVISION_KEY = 'upsertSubdivision';
export const DELETE_SUBDIVISION_KEY = 'deleteSubdivision';

// Department
export const GET_DEPARTMENTS_KEY = 'departments';
export const UPSERT_DEPARTMENT_KEY = 'upsertDepartment';
export const DELETE_DEPARTMENT_KEY = 'deleteDepartment';

// Commodity
export const GET_COMMODITIES_KEY = 'commodities';
export const UPSERT_COMMODITY_KEY = 'upsertCommodity';
export const DELETE_COMMODITY_KEY = 'deleteCommodity';

// Field
export const GET_FIELDS_KEY = 'fields';
export const UPSERT_FIELD_KEY = 'upsertField';
export const DELETE_FIELD_KEY = 'deleteField';

// Rootstock
export const GET_ROOTSTOCKS_KEY = 'rootstocks';
export const UPSERT_ROOTSTOCK_KEY = 'upsertRootstock';
export const DELETE_ROOTSTOCK_KEY = 'deleteRootstock';

// Variety
export const GET_VARIETIES_KEY = 'varieties';
export const UPSERT_VARIETY_KEY = 'upsertVariety';
export const DELETE_VARIETY_KEY = 'deleteVariety';

/*================================================================================================================
*                                             Dev Tools Master Data
* ==============================================================================================================*/

// Contact Us
export const GET_CONTACTS_KEY = 'contacts';
export const UPSERT_CONTACT_KEY = 'upsertContact';
export const DELETE_CONTACT_KEY = 'deleteContact';

// News
export const GET_NEWS_KEY = 'news';
export const UPSERT_NEWS_KEY = 'upsertNews';
export const DELETE_NEWS_KEY = 'deleteNews';

// FrequentlyAskedQuestion
export const GET_FREQUENTLY_ASKED_QUESTIONS_KEY = 'frequentlyAskedQuestion';
export const UPSERT_FREQUENTLY_ASKED_QUESTION_KEY = 'upsertfrequentlyAskedQuestion';
export const DELETE_FREQUENTLY_ASKED_QUESTION_KEY = 'deletefrequentlyAskedQuestion';

// Logging
export const GET_LOGS_KEY = 'logs';

/*================================================================================================================
*                                                    Fresh Service
* ==============================================================================================================*/

export const UPSERT_FRESH_SERVICE_TICKET = 'upsertFreshServiceTicket';

/*================================================================================================================
*                                                  Transactional
* ==============================================================================================================*/

// Dispatch
export const GET_DISPATCHES_KEY = 'dispatches';
export const UPSERT_DISPATCH_KEY = 'upsertDispatch';
export const DELETE_DISPATCH_KEY = 'deleteDispatch';

// DispatchLine
export const GET_DISPATCH_LINES_KEY = 'dispatchLines';
export const UPSERT_DISPATCH_LINE_KEY = 'upsertDispatchLine';
export const DELETE_DISPATCH_LINE_KEY = 'deleteDispatchLine';

// Intake
export const GET_INTAKES_KEY = 'intakes';
export const UPSERT_INTAKE_KEY = 'upsertIntake';
export const DELETE_INTAKE_KEY = 'deleteIntake';

// IntakeLine
export const GET_INTAKE_LINES_KEY = 'intakeLines';
export const UPSERT_INTAKE_LINE_KEY = 'upsertIntakeLine';
export const DELETE_INTAKE_LINE_KEY = 'deleteIntakeLine';

// Order
export const GET_ORDERS_KEY = 'orders';
export const UPSERT_ORDER_KEY = 'upsertOrder';
export const DELETE_ORDER_KEY = 'deleteOrder';

// OrderLine
export const GET_ORDER_LINES_KEY = 'orderLines';
export const UPSERT_ORDER_LINE_KEY = 'upsertOrderLine';
export const DELETE_ORDER_LINE_KEY = 'deleteOrderLine';

// Stock
export const GET_STOCKS_KEY = 'stocks';
export const UPSERT_STOCK_KEY = 'upsertStock';
export const DELETE_STOCK_KEY = 'deleteStock';

// StockAudit
export const GET_STOCK_AUDITS_KEY = 'stockAudits';
export const UPSERT_STOCK_AUDIT_KEY = 'upsertStockAudit';
export const DELETE_STOCK_AUDIT_KEY = 'deleteStockAudit';

// Transfer
export const GET_TRANSFERS_KEY = 'transfers';
export const UPSERT_TRANSFER_KEY = 'upsertTransfer';
export const DELETE_TRANSFER_KEY = 'deleteTransfer';

// Trip
export const GET_TRIPS_KEY = 'trips';
export const UPSERT_TRIP_KEY = 'upsertTrip';
export const DELETE_TRIP_KEY = 'deleteTrip';