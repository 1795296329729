import { useQuery, useMutation } from '@tanstack/react-query';
import RightHttpService from '../../../service/http/right/rightHttpService';
import { GET_RIGHTS_KEY, UPSERT_RIGHT_KEY, DELETE_RIGHT_KEY } from '../queryConstants';
import { queryErrorHandler, querySuccessHandler, queryDeleteSuccessHandler } from '../queryStateHandlers';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';
import { IBaseRight } from '@zz2/zz2-ui';
import { IRightUpsert } from '../../../@types/model/right/right/right';

export const useGetRights = () => {
    const query = useQuery<Array<IBaseRight>, Error>({
        queryKey: [GET_RIGHTS_KEY],
        queryFn: ({ signal }) => RightHttpService.getList(signal),
        onError: queryErrorHandler('Error loading Rights.'),
    });

    return query;
};

export const useHandleRightUpsert = () => {
    const result = useMutation<IBaseRight, Error, { upsert : IRightUpsert; type : UpsertTypeEnum }>({
        mutationKey: [UPSERT_RIGHT_KEY],
        mutationFn: (args : { upsert : IRightUpsert; type : UpsertTypeEnum }) => RightHttpService.upsert(args.upsert, args.type),
        onError: queryErrorHandler('Error creating/updating Right.'),
        onSuccess: querySuccessHandler([GET_RIGHTS_KEY], 'Right updated successfully.'),
    });

    return result;
};

export const useHandleRightDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_RIGHT_KEY],
        mutationFn: (rightId : number) => RightHttpService.delete(rightId),
        onError: queryErrorHandler('Error deleting Place.'),
        onSuccess: queryDeleteSuccessHandler([GET_RIGHTS_KEY], 'Right inactivated successfully.'),
    });

    return result;
};