import axios, { AxiosPromise } from 'axios';
import { IUserToken } from '../../../@types/model/right/userToken/userTokenModels';

export default class AuthHttpService {
    private static readonly PATH = '/Authorisation'

    public static signUp = (employeeNumber : string, password : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Authorisation/SignUp`, {
            employeeNumber,
            password,
        });
    }

    public static googleLogIn = (code : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Authorisation/LogInGoogle`, {
            code,
        });
    }

    public static logIn = (username : string, password : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Authorisation/LogIn`, {
            username,
            password,
        });
    }

    public static refreshSession = () : AxiosPromise<IUserToken> => {
        return axios.get(`${API_URL}/v1/Authorisation/RefreshSession`);
    }
}
