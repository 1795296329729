import axios from 'axios';
import { IRightUpsert } from '../../../@types/model/right/right/right';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';
import { IBaseRight } from '@zz2/zz2-ui';

export default class RightHttpService {
    public static getList = async (signal ?: AbortSignal) : Promise<Array<IBaseRight>> => {
        const result = await axios.get(`${API_URL}/v1/Right/Right/GetList`, {
            signal
        });

        return result.data;
    }

    public static upsert = async (rightUpsert : IRightUpsert, type : UpsertTypeEnum) : Promise<IBaseRight> => {
        const result = await axios.post(`${API_URL}/v1/Right/Right/${type}`, rightUpsert);
        return result.data;
    }

    public static delete = async (rightId : number) : Promise<void> => {
        const result = await axios.delete(`${API_URL}/v1/Right/Right/Delete`, {
            params: { rightId },
        });

        return result.data;
    }
}
