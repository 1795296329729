import { createReducer } from '@reduxjs/toolkit';
import FreshServiceActions from './actions';

export interface IFreshServiceState {
    isSupportTicketDialogOpen : boolean;
    supportTicketDescription : string;
}

const initialState = {
    isSupportTicketDialogOpen: false,
    supportTicketDescription: '',
};

const freshServiceReducer = createReducer<IFreshServiceState>(initialState, (builder) => {
    builder.addCase(FreshServiceActions.setIsSupportTicketDialogOpen, (state, action) => {
        state.isSupportTicketDialogOpen = action.payload;
    });
    builder.addCase(FreshServiceActions.setSupportTicketDescription, (state, action) => {
        state.supportTicketDescription = action.payload;
    });
    builder.addCase(FreshServiceActions.reset, () => {
        return initialState;
    });
});

export default freshServiceReducer;
